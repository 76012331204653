.imprint-content{
    width: 85vw;
    margin: 0 auto;
    margin-top: 20vh;
    color: white !important;
    font, p{
        color: white;
    }
    h1{
        font-size: 50px;
        word-wrap: break-word;
    }
}