@media screen and (max-width: 1478px) {

    /* NAV */ 
    .navbar .nav-content .nav-links .normal-link{
        margin: 15px
    }
    .navbar .nav-content .nav-links .logo-desktop{
        margin: 0 20px;
    }
}
@media screen and (max-width: 1250px) {

    /* REFERENCES */ 
    .references-section{
        gap: 40px;
        h2{
            font-size: 4.5vw;
        }
        p{
            font-size: 3.5vw;
        }
    }
     /* Calendar Section */
     .calendar-section .calendar-grid  .modal-content {
        width: 80vw;
        margin: 25% auto;
    }

 /* Nav Active */
 .nav-active {
    -webkit-transform: translateX(0%) !important;
    transform: translateX(0%) !important;
    transition: transform .5s ease-in !important;
    position: fixed !important;
}
.burger-align .nav-active {
    position: relative !important;
}
.nav-active.navbar{
    top: 0 !important;
}
.navbar {
    z-index: 900;
    .nav-content{
        position: relative;
        .logo-mobile{
            display: inline-block;
            position: relative;
            z-index: 910;
            margin-left: 7.5vw;
            a{
                text-decoration: none;
                color: black;
                p{
                    margin-top: -2px;
                }
            }
            .site-switcher{
                margin-top: 5px;
                .site-switch{
                    display: inline-block;
                    padding: 2px 15px 2px 15px;
                    font-size: 16px;
                    opacity: .4;
                }
                a:nth-child(1){

                    border-right: 1px solid rgba(255, 255, 255, .4);
                    padding: 2px 15px 2px 0px;

                }
                .active-site{
                    opacity: 1;
                }
            }
        }
        .logo-desktop{ 
            display: none;
        }
    }
    .nav-links {
        position: fixed;
        margin-left: 0px;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: center;
        background-color: white;
        flex-direction: column;
        align-items: center;
        transform: translateX(100%);
        -webkit-transform: translateX(100%);
        transition: transform 0.5s ease-in;
        -webkit-transition: transform 0.5s ease-in;
        color: black;

        .normal-link{
            opacity: 0;
            margin-top: 20px;
            position: relative !important;
        }

        &.nav-active .normal-link {
            animation: navLinkFade .6s ease forwards;
            animation-delay: .5s;
        }
        &.nav-active .normal-link:nth-child(2) {
            animation-delay: .7s;
        }
        &.nav-active .normal-link:nth-child(3) {
            animation-delay: .9s;
        }
        &.nav-active .normal-link:nth-child(4) {
            animation-delay: 1s;
        }
        &.nav-active .normal-link:nth-child(5) {
            animation-delay: 1.2s;
        }
        &.nav-active .normal-link:nth-child(6) {
            animation-delay: 1.4s;
        }
        &.nav-active .normal-link:nth-child(7) {
            animation-delay: 1.6s;
        }
        &.nav-active .normal-link:nth-child(8) {
            animation-delay: 1.8s;
        }
    }
    .logo {
        svg {
            display: inline-block;
            width: 40px;
            height: auto;
        }
        h1 {
            font-size: 28px;
        }
    }
    .nav-margin {
        margin-top: -70vh;
    }
    .burger {
        display: block !important;
    }
    .language-switch-desktop{
        display: none;
    }
	.nav-content .language-switch-mobile{
        display: block;
        }
    .nav-active.burger{
        transform: translateY(-50%) !important;
    }
    
}
/* HERO */ 
.hero-grid{
    margin-top: 100px
}

}

@media screen and (max-width: 1200px) {

    /* CONTACT */ 
    .contact-grid .item:nth-child(1){
        margin-left: 7.5vw
    }

    /* GALLERY */ 
    .SRLImage{
        margin-top: -15px;
    }
    .SRLCaptionContainer{
        margin-top: 25px;
    }


    /* MEDIA */ 
    .media-bg .media-content .youtube-section iframe{
        min-height: 600px;
    }

    /* START */ 
    #logo {
        width: 85vw;
    }
        /* Calendar Section */
        .calendar .calendar-grid  .modal-content {
            width: 80vw;
            margin: 25% auto;
        }

}

@media screen and (max-width: 1080px) {
/* START */ 
.start, .calendar, .contact-grid{
    margin-top: 95px; 

}
.start .featured-section{
    width: 50vw;
}
/* MEDIA */ 
#arrowR, #arrowL{
    display: block;
}
.navbar-media .bottom-bar{
    &::after{
        right: 0;
        background: linear-gradient(90deg,rgba(247, 247, 247, 0) 0, #181818 70.5%, #181818);
        z-index: 489489;
        content: '';
width: 150px;
height: 100%;
position: absolute;
top: 0;
bottom: 0;
pointer-events: none;
-webkit-transition: opacity 200ms ease;
transition: opacity 200ms ease;
}

&::before{
left: 0;
background: linear-gradient(270deg, rgba(247, 247, 247, 0) 0, #181818 99.5%, #181818);
content: '';
z-index: 3342424;
width: 150px;
height: 100%;
position: absolute;
top: 0;
bottom: 0;
pointer-events: none;
-webkit-transition: opacity 200ms ease;
transition: opacity 200ms ease;
      }
}

    /* PAEDAGOGIK */ 
    .paedagogik-section{
        background: linear-gradient(180deg, #232122 0.76%, rgba(35, 33, 34, 0) 14.93%), linear-gradient(0deg, #232122 3.81%, rgba(35, 33, 34, 0) 29.22%), linear-gradient(266.91deg, #232122 3.79%, rgba(35, 33, 34, 0) 173.47%), url(../assets/paedagogik2.jpg);
        justify-content: flex-start;
        .container-item{
            margin-left: 7.5vw;
            width: 85vw;
        }
       }
 
}
@media screen and (max-width: 950px) {
    /* ENSEMBLES */ 
    .ensembles-section .ensembles-grid{
        grid-template-columns: repeat(1, 1fr);
        gap: 30px;
    }
    .ensembles-section .sub-item .overlay .text{
        font-size: 7vw;
    }
    .ensemble-s{
        .item{
            width: 100%;
        }
    }
        /* SWITCH */ 
        .switch-section .switch-container .switch-grid a h2{
            font-size: 6vw;
        }
    /* START */ 
    .start .featured-section{
       // float: left;
        margin-left: 7.5vw;
        width: 40vw;
    }
    /* BIO */ 
    .item-press .press-grid{
        grid-template-columns: repeat(2, 1fr);
    }

        /* GALLERY */ 

        .gallery-section .gallery-preview{
            grid-template-columns: repeat(1, 1fr);
            width: 100%;
        }

    /* GALLERY */ 
    .gallery-e-section .gallery-e-grid{
        grid-template-columns: repeat(2, 1fr);
    }
    .gallery-section .gallery-preview{
        grid-template-columns: repeat(1, 1fr);
    }

    /* MEDIA */ 
    .media-bg .media-content .soundcloud-section{
        grid-template-columns: repeat(1, 1fr);
    }
    .media-bg .media-content .youtube-section iframe{
        min-height: 400px;
    }
    .media-bg .media-content .media-grid-2{
        grid-template-columns: repeat(1, 1fr);
    }
    .media-bg .media-content ul{
        flex-wrap: wrap;
        li{
            margin-top: 10px;
        }
    }
    .media-bg .media-content .impressionen-section img{
        width: 85vw;
    }
    .media-bg .media-content .impressionen-section .container-arrow{
        width: 85vw;
    }

    /* CALENDAR */ 
    .calendar{
     //  background: linear-gradient(360deg, #000000 2.11%, rgba(0, 0, 0, 0) 19.48%), linear-gradient(107.11deg, rgba(0, 0, 0, 0.65) 17.1%, rgba(0, 0, 0, 0) 143.01%), url("../assets/calendar-mobile.jpg"), black; 
       background-size: 100%;
       background-repeat: no-repeat;   

    }


    /* BIO */
    .bio-f{
        background:  linear-gradient(180deg, #232122 0%, rgba(35, 33, 34, 0) 5.25%),linear-gradient(360deg, #232122 0%, rgba(18, 18, 18, 0) 50%), url('../assets/hero-f-mobile.jpg');
        background-position-x: center;
        background-size: 100%;
        background-repeat: no-repeat;
    }
    .bio-s{
        background:  linear-gradient(180deg, #232122 0%, rgba(35, 33, 34, 0) 5.25%),linear-gradient(360deg, #232122 0%, rgba(18, 18, 18, 0) 50%), url('../assets/hero-s-mobile.jpg');

        background-position-x: center;
        background-size: 100%;
        background-repeat: no-repeat;
    }
    .animation-bi{

        .trigger .textbio .inside-bio .bio-text{
            column-count: 1;
            p{
                font-size: 14.5px;
            }
        }
    }
    /* PAEDAGOGIK */ 
    .paedagogik-section .container-item p{
        font-size: 14.5px;
    }
}
@media screen and (max-width: 888px) {
    /* REFERENCES */ 
    .references-section{
        grid-template-columns: repeat(1, 1fr);
        gap: 75px;
    }

    /* MEDIA */ 
    .youtube-section{
        .sounds-s{
            iframe{
                width: 100%;
            }
        }
    }
    /* CALENDAR */ 
    .calendar-section .calendar-grid .calendar-inner-grid{
        grid-template-columns: 100%;
        gap: 30px;
    }
    .calendar-section .calendar-grid .calendar-inner-grid .item:nth-child(2) h2{
        font-size: 30px;
    }
    .calendar-section .calendar-grid .calendar-inner-grid .item:nth-child(2) p{
        font-size: 16px;
    }

}
@media screen and (max-width: 777px) {
    /* HERO */ 
    .hero-grid .item h2{
        font-size: 82px;
    }
    /* GALLERY */ 
    .imprint-content h1{
        font-size: 7vw;
    }
    /* FOOTER */ 
    footer {
        .footer-section {
            padding-bottom: 27px;
            .item {
                margin-top: 7px;
            }
            .item-3 {
                text-align: left;
            }
        }
    }
    .calendar-section .calendar-grid .item .modal-content {
        h1 {
            font-size: 35px;
        }
        .sub {
            font-size: 15px;
        }
        .information-2 {
            font-size: 17px;
        }
    }

    /* CONTACT */
    .contact-section{
    background: linear-gradient(180deg, #232122 0.76%, rgba(35, 33, 34, 0) 14.93%), linear-gradient(360deg, rgba(35, 33, 34, 0.7) 2.22%, rgba(35, 33, 34, 0.7) 105.96%), url(../assets/contact-mobile.jpg);
    
    }
    /* IMPRESSIONS */ 
    .impressionen-section {
        .slick-slider{
        width: 80vw;
        }
        img{
            width: 80vw;
        }
        .container-arrow{
            width: 80vw;
        }
        p{
            font-size: 14.5px;
            margin-bottom: 6px;
        }
    }

        /* CONTACT */ 
        .trigger-contact .textcontact .inside-bio{
            width: 85vw;
        }
}
@media screen and (max-width: 700px) {
    /* GENERAL */ 
    .normal-headline{
        font-size: 52px;
    }
     /* CALENDAR */ 
     .no-event-grid{
        grid-template-columns: repeat(1, 1fr);
        width: 85vw;
    }
    /* REFERENCES */ 
    .references-section h2{
        width: 100%;
    }
    /* START */ 
    .start{
      //  background: url(../assets/dominik-start-mobile.jpg), black;
        background-size: 100%;
        min-height: 1800px;
        background-repeat: no-repeat;
    }
     .start .featured-section{
        margin-left: 7.5vw;
        float: left;
        width: 85vw;
        margin-top: 95vh;
        grid-template-columns: 50% 50%, 100%;
        .iframes{
            grid-template-columns: repeat(2, 1fr);
        } 
    }
    .start .featured-section .iframe-setion iframe{
        min-height: 180px;
    }
  
    
    /* CALENDAR */ 
    .calendar-section .calendar-grid .item .modal-content {
        h1 {
            font-size: 35px;
        }
        .sub {
            font-size: 15px;
        }
        .information-2 {
            font-size: 17px;
        }
}
/* MEDIA */ 
.navbar-media .bottom-bar ul li:nth-child(1){
    margin-left: 30px;
} 
.navbar-media .bottom-bar ul li:last-child{
    margin-right: 120px;
}
/* CONTACT */ 
    .contact-grid{
        background-position-x: 8%;
      //  background: linear-gradient(97.08deg, #000000 14.15%, rgba(0, 0, 0, 0) 96.13%),
    //    url('../assets/dominik-contact-mobile.jpg');
	    background-size: cover;
    }

    .contact-grid h2{
        font-size: 62px;
    }
    .contact-grid .item .contact-item .hover-box .center-link a{
        font-size: 18px;
        margin-left: 45px;
    }
    .contact-grid .item .contact-item svg{
        width: 28px;
    }
    .contact-grid .item .contact-item{
        margin-top: 10px
    }

    /* MEDIA */ 
    .media-bg .media-content .impressionen-section p{
        font-size: 14px;
        margin-bottom: 5px;
    }
    .media-bg .media-content .youtube-section a{
        display: flex;
        justify-content: center;
    }
    

}
@media screen and (max-width: 590px) {
    /* PAEDAGOGIK */
    .paedagogik-section{
        background-position-x: 10%;
    }
    /* MEDIA */ 
    .soundcloud-section .row-1, .soundcloud-section .row-2{
        grid-template-columns: repeat(1, 1fr);
    }

    


    /* SWITCH */ 
    .switch-section .switch-container-desktop{
        display: none;
    }
    .switch-section .switch-container-mobile{
        display: block;
        h1{
            font-size: 11vw;
        }
    }
    .switch-section .switch-container .switch-grid{
        grid-template-columns: repeat(2, 1fr);
        gap: 0;
        margin-top: 50px;

        .item{
            padding: 15px 15px 15px 15px;
            h2{
                font-size: 6vw;
            }
            &:nth-child(1){
                border-right: 1px solid rgba(255, 255, 255, .4);
            }
            .line{
                display: none;
            }
        }
    }
}
@media screen and (max-width: 517px) {
    /* FOOTER */
    footer .footer-section {
        .item {
            text-align: center
        }
    }
    /* GALLERY */ 
    .gallery-e-section .gallery-e-grid{
        grid-template-columns: repeat(1, 1fr);
    }
    /* ABOUT */ 
    .youtube-section .sound-F{
            width: 85vw;
    }
    /* CALENDAR */ 
    .calendar-section .calendar-grid .calendar-inner-grid .item:nth-child(2) h2{
        font-size: 23px;
    }
    .calendar-section .calendar-grid .calendar-inner-grid .item:nth-child(2) .view-more-btn{
        padding: 10px 0px;
        width: 95%;
        text-align: center;
    }
    .Collapsible .Collapsible__trigger{
        font-size: 30px;
    }
    
    /* MEDIA */ 

    .media-bg .media-content .youtube-section iframe{

            height: 240px !important;
            min-height: 240px !important;
    }
    .media-bg .media-content .social-media-content .social-link-section a:nth-child(1) img, .media-bg .media-content .social-media-content .social-link-section a img{
        width: auto;
        height: 50px;
        margin: 20px
    }
    /* BIO */ 
    .item-press .press-grid{
        grid-template-columns: repeat(1, 1fr);
    }

}

@media screen and (max-width: 500px) {
    /* GENERAL */ 
    .normal-headline{
        font-size: 45px;
    }
    /* START */
    .start .featured-section{
        margin-top: 95vh;
    }
       /* START */ 
       .start{
        min-height: 1650px;
       }

         /* GALLERY */ 
    .css-1x4ynwp::before {
        font-size: 16px;
    }  
    
    .start .featured-section .iframe-section iframe{
        height: 180px;
        min-height: 180px;
    }
    /* MEDIA */ 
    .media-bg .media-content ul li{
        width: 100%;
    }
    .navbar-media .bottom-bar::after{
        background: linear-gradient(90deg, rgba(247, 247, 247, 0) 0, #181818 99.3%, #181818);
    }
    .navbar-media .bottom-bar::before{

    background: linear-gradient(270deg, rgba(247, 247, 247, 0) 0, #181818 99.9%, #181818);
    }

    /* INTRO */ 
    .intro .intro-text h1{
        font-size: 55px ;
    }
    .intro .intro-text h1:nth-child(2){
        font-size: 23px;
    }
    /* HERO */ 
    .hero-grid .item h2{
        font-size: 55px;
    }
    /* IMPRESSIONS */ 
    .impressionen-section h2{
        font-size: 12vw;
    }
        /* MEDIA */ 
        .youtube-section{

            .sounds-s{
                grid-template-columns: repeat(1, 1fr);
                iframe{
                    width: 100%;
                }
            }
        }
        .contact-item .hover-box .center-link a{
            font-size: 19px;
        }
        /* PÄDAGOGIK */ 
        .paedagogik-section{
            height: auto;
            background-size: 280% !important;
            background-repeat: no-repeat !important;
        }
}
@media screen and (max-width: 350px) {



    /* NAV */ 
    .navbar .nav-content .nav-links{
        padding-top: 45px;
    }
    .navbar .nav-content .logo-mobile a{
        h1{
            font-size: 28px;
        }
    }

    /* INTRO */
    .intro .intro-text h1{
        font-size: 45px ;
    }
    .intro .intro-text h1:nth-child(2){
        font-size: 18px;
    }

    /* BIO */ 
    .item-press h2{
        font-size: 52px;
    }
    /* CONTACT */ 
    .contact-item .hover-box .center-link a{
        font-size: 17px;
    }
    .contact-item .agency-box p{
        font-size: 14px;
    }
}
@media screen and (max-width: 300px) {

    /* CALENDAR */

    .contact-grid .item .contact-item .hover-box .center-link a{
        font-size: 13px;
    }

}
