.paedagogik-section{
    width: 100vw;
    height: 101vh;
    min-height: 800px;
    background: linear-gradient(1.22deg, #232122 2.8%, rgba(35, 33, 34, 0) 37.6%), url(../assets/paedagogik2.jpg);
    background-size: cover !important;
    margin-bottom: 20vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .container-item{
        float: right;
        margin-right: 7.5vw;
        width: 45vw;
        h2{
            margin-bottom: 20px;
        }
        p{
            line-height: 1.8;
            margin-bottom: 20px;
        }
    }
}