        /* IMPRESSIONEN */
        .impressionen-section{
            margin-bottom: 20vh;
            h2{
                margin-bottom: 50px;
                text-align: center;
            }
            img{
                width: 55vw;
            }
            p{
                font-family: 'Open Sans';
                margin-top: 7px;
                font-size: 18px;
                opacity: .7;
            }
            .slick-slider{
                width: 55vw;
                margin: 0 auto;
            }
            .container-arrow{
                width: 55vw;
             
                margin: 0 auto;
                .slick-arrow{
                    float: right;
                    background: none;
                z-index: 200;
        
                    border: none;
                    outline: none;
                    cursor: pointer;
                    &:nth-child(1){
                        margin-left: 10px;
                    }
                }
            }
        }