


.calendar-section {
    margin: 0 auto;
    margin-top: 0vh; 
    margin-bottom: 10vh;
    width: 85vw;
    .calendar-grid {
        
        margin-top: 80px;
        .preview {
            width: 100%;
        }
        
        .calendar-inner-grid{
            display: grid;
            grid-template-columns: 30% 70%;
            border-bottom: 1px solid rgba(255, 255, 255, .4);
            padding-bottom: 30px;
            &:last-child{
                border-bottom: none;
            }
            margin-bottom: 25px;
            .item:nth-child(1){
                display: flex;
                align-items: center;
                justify-content: center;
                p{
                    font-size: 75px;
                    font-weight: 600;
                }
            }
            .item:nth-child(2){
                h2{
                    font-size: 40px;
                    font-family: 'open sans';
                    width: 80%;
                }
                p{
                    margin-top: 10px;
                    font-size: 18px;
                }
                .view-more-btn {
                    border: 1.5px solid white;
                    font-weight: 400;
                    color: white;
                    outline: none;
                    margin-top: 20px;
                    display: inline-block;
                    background: transparent;
                    padding: 10px 65px;
                    text-decoration: none;
                    font-size: 16px;
                    transition: .3s ease;
                    font-family: 'Open sans', Arial;
                    cursor: pointer;
    
                    &:hover {
                        background: white;
                        color: black;
                    }
                }
            }
        }
    } 
  }

  
  .Collapsible {
    margin-bottom: 30px;
    .Collapsible__trigger{
        width: 100%;
    font-family: 'open sans';
    font-size: 38px;
    padding-bottom: 6px;
    margin-bottom: 25px;
    position: relative;

    border-bottom: 1px solid white;


        display: block;
        cursor: pointer;
    }
    .is-closed{
        &::before{
            content: url(../assets/plus.svg) !important;
            position: absolute;
            right: 0;
        }
  }
    .is-open{
        &::after{
            content: url(../assets/minus.svg) !important;
            position: absolute;
            right: 0;
        }
  }
}
/*
.iframe-style {
    position: absolute;
    width: 80%;
    bottom: 10px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    color: black;
    text-align: center;
}

.calendar-section {
    width: 85vw;
    margin: 0 auto;
    margin-bottom: 20vh;
    margin-top: 5vh;
    h2{
        margin-bottom: 50px;
    }
    // grid-template-columns: repeat(1, 1fr);
    font-family: 'Montserrat', Arial;

    .calendar-grid {
        display: grid;
        grid-gap: 80px;
        width: 100%;
        grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
        .item{
            position: relative;
            h3 {
                font-weight: bold;
                font-size: 50px;
            }
            h4 {
                font-size: 130px;
                opacity: .09;
                position: absolute;
                top: -30%;
                right: 10%;
                display: none;
            }
            h5 {
                font-weight: 500;
                font-size: 34px;
                margin-top: 20px;
                width: 95%;
            }
            p {
                font-size: 16px;
                width: 85%;
                margin-top: 5px;
                margin-bottom: 30px;
            }
            .view-more {
                border: 1.5px solid white;
                font-weight: 400;
                color: white;
                outline: none;
                background: transparent;
                padding: 10px 65px;
                text-decoration: none;
                font-size: 16px;
                transition: .3s ease;
                font-family: 'Open sans', Arial;
                cursor: pointer;

                &:hover {
                    background: white;
                    color: black;
                }
            }
                border-right: 1px solid rgba(255, 255, 255, 0.3);
                &:last-child{
                    border: none
                }
        }
    }
    .calendar-btn{
        width: 60%;
        padding: 10px 0px;
        color: white;
        text-decoration: none;
        text-align: center;
        border: 1px solid white;
        display: block;
        margin: 0 auto;
        margin-top: 60px;
        transition: background .5s ease, color .5s ease;
        &:hover{
            background: white;
            color: black;
        }

    }
}

@supports not (display: grid) {
    .calendar-section {
        .calendar-grid {
            display: flex;
            flex-wrap: wrap;
            .item {
                width: 400px;
                margin-right: 40px;
                margin-top: 60px;
            }
        }
    }
}


/* Modal Content/Box

.calendar-section .calendar-grid .item {
    .modal {
        // display: none;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgb(0, 0, 0);
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 99999999;
    }
    .modal-content {
        display: block;
        background-color: white;
        margin: 8% auto;
        padding: 20px;
        width: 50%;
        position: relative;
        animation-name: animatetop;
        animation-duration: 0.4s;
        text-align: left;
        h1 {
            text-align: left;
            font-size: 52px;
            color: black !important;
            line-height: 1;
            word-wrap: break-word;
        }
        .sub {
            margin-top: 10px;
            opacity: .8;
            font-size: 23px;
            margin-bottom: 40px;
            color: black;
        }
        .information-2 {
            font-size: 21px;
            margin-top: 30px;
            line-height: 1.7;
            margin-bottom: 30px;
            color: black;
        }
        a {
            padding: 10px 60px;
            text-decoration: none;
            color: white;
            background-color: black;
            border-radius: 8px;
            font-size: 19px;
        }
        .map {
            margin-top: 60px;
            iframe {
                width: 100%;
                height: 30vh;
            }
        }
    }
    @keyframes animatetop {
        from {
            top: -300px;
            opacity: 0
        }
        to {
            top: 0;
            opacity: 1
        }
    }
}


/* The Close Button 

.close {
    color: black;
    opacity: .4;
    font-size: 45px;
    font-weight: bold;
    width: 100%;
    transition: .2s;
    display: flex;
    justify-content: flex-end;
    margin-top: -10px;
}

.close:hover,
.close:focus {
    color: black;
    opacity: 1;
    text-decoration: none;
    cursor: pointer;
}

.old-event .first-view {
    opacity: .3;
}

.no-event-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    .no-events {
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        align-items: center;
        padding: 30px 35px;
        background-color: rgba(30, 30, 30, 0.1);
    }
}

*/

.no-event-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    .no-events {
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        align-items: center;
        padding: 30px 35px;
        background-color: rgba(255, 255, 255, 0.1);
        font-family: 'Open Sans';
    }
}



/* Modal Content/Box */

.calendar-section .calendar-grid {
	.modal {
		// display: none;
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		overflow: auto;
		background-color: rgb(0, 0, 0);
		background-color: rgba(0, 0, 0, 0.4);
		z-index: 50000000;
	}
	.modal-content {
		display: block;
		background-color: white;
		margin: 8% auto;
		padding: 20px;
		width: 50%;
		position: relative;
		animation-name: animatetop;
		animation-duration: 0.4s;
		text-align: left;
		h1 {
			text-align: left;
			font-size: 52px;
			color: black !important;
			line-height: 1;
		}
        h2{
            color: black;
            margin-top: 10px;
        }
		.sub {
			margin-top: 10px;
			opacity: .8;
			font-size: 23px;
			margin-bottom: 40px;
			color: black;
		}
		.information-2 {
			font-size: 21px;
			margin-top: 30px;
			line-height: 1.7;
			margin-bottom: 30px;
			color: black;
		}
		a {
			padding: 10px 60px;
			text-decoration: none;
			color: white;
			border-radius: 8px;
			font-size: 19px;
			background: black;
		}
		.map {
			margin-top: 60px;
			iframe {
				width: 100%;
				height: 30vh;
			}
		}
	}
	@keyframes animatetop {
		from {
			top: -300px;
			opacity: 0;
		}
		to {
			top: 0;
			opacity: 1;
		}
	}
}

/* The Close Button */

.close {
	color: black;
	opacity: .4;
	font-size: 45px;
	font-weight: bold;
	width: 100%;
	transition: .2s;
	display: flex;
	justify-content: flex-end;
	margin-top: -10px;
}

.close:hover,
.close:focus {
	color: black;
	opacity: 1;
	text-decoration: none;
	cursor: pointer;
}

.no-event-grid {
	margin-top: 20px;
	.no-events {
		display: flex;
		-webkit-box-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		align-items: center;
		font-family: 'Open Sans';
		padding: 30px 35px;
		color: white;
		background-color: rgba(40, 40, 40, 0.7);
	}
}
.old-event .information,
.old-event .view-more-center {
	opacity: .4;
}

.calendar-page {
	margin-top: 25vh;
}
.link-container {
	text-align: center;
	display: block;
	margin-top: 8vh;

	.calendar-btn {
		text-decoration: none;
		font-size: 15px;
		-webkit-transition: 400ms;
		transition: 400ms;
		background: transparent;
		padding: 10px 160px;
		border: 2px solid white;
		color: white;
		font-weight: 400;
		&:hover {
			background: white;
			color: black;
		}
	}
}
