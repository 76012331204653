
.contact-section{
    height: auto;
    width: 100vw;
    background: linear-gradient(180deg, #232122 0%, rgba(35, 33, 34, 0) 23.25%),linear-gradient(360deg, #232122 0%, rgba(18, 18, 18, 0) 50%), url(../assets/contact.jpg);
    background-position-x: center !important;
    background-size: cover !important;
    transition: all .4s;
    margin-top: 20vh;
    .animation-contact{
    height: 100%;
    min-height: 1000px;
    width: 100vw;
    transition: all .4s;
	position: relative;
	
    }


}
.trigger-contact{
.textcontact{
    height: auto;
    width: 85vw;
    margin: 0 auto;
    color: white;
	//display: flex;
	//justify-content: center;

    .inside-bio{
        padding-top: 160px; 
		//width: 40vw;
		//margin: 0 auto;


		h2{
			text-align: center;
		}
        
    }


}
}

.contact-item {
	margin-top: 30px;
	svg{
		width: 35px;
		height: auto;

	}
	.agency-box{
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		a:nth-child(1){
			position: relative;
		}
		svg{
			opacity: 1;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
			
			p{
				color: white;
				opacity: .7;
				text-align: center;

			}
			svg{
				margin-left: -4px;
				width: 39px;
				display: none;

			}

			a {
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				text-decoration: none;
				color: white;
				transition: .3s;
				&:hover {
					opacity: 1;
				}
				
			}
	}
	.hover-box {
		display: flex;
                align-items: center;
                justify-content: center;
                a:nth-child(1){
                    position: relative;
                }
                svg{
                    opacity: 1;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }
		.center-link {
		

			a {
				width: auto;
				font-style: normal;
				font-weight: 400;
				font-size: 22px;
				opacity: .7;
				text-decoration: none;
				color: white;
				transition: .3s;
				margin-left: 45px;
			
				margin-top: -2.5px;

			
				&:hover {
					opacity: 1;
				}
			}
		}
	}
}


.bio-btn{
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    width: 210px;
    margin: 0 auto;
    border: 1px solid white;
    padding: 10px 20px;
font-size: 22px;
margin-bottom: 50px;
    color: white;
    text-decoration: none;
    margin-top: 30px;
    transition: background .5s ease, color .5s ease;
    svg{
        width: 30px;
        height: auto;
        path{
            fill: white;
            transition: fill .5s ease;
        }
    }
    &:hover{
        background: white;
        color: black;
        svg path{
            fill: black;
        }
    }
}