

/* NAVBAR  */
.navbar {
	background-color: white;
	height: auto;
	width: 100vw;
	position: fixed;
	top: 0;
	z-index: 999;

	.nav-content {
		margin: 0px auto;
		padding: 20px 0px;
		position: relative;
		.logo-mobile{
			display: none;
		}
		.nav-links {
			display: flex;
			align-items: center;
			justify-content: center;
			-webkit-justify-content: center;
			li{
				list-style: none;
			}

			.normal-link{
					margin: 30px;
					font-size: 18px;
				}
				.logo-desktop {
					text-align: center;
					margin: 0 50px;
					a{
						h1{
							margin-bottom: 5px;
							font-size: 45px;
								}
					}
						.site-switcher a:nth-child(1){

								border-right: 1px solid rgba(255, 255, 255, .4);

							}
						
						.site-switch{
							display: inline-block;
			    			padding: 4px 15px 4px 15px;
							font-size: 21px;
							opacity: .4;
						}
						.active-site{
							opacity: 1;
						}
				}
				
				a {
					color: black;
					text-decoration: none;
				}	
		}
		.burger {
			display: none;
			cursor: pointer;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 7.5%;
			.line {
				height: 3px;
				background-color: black;
				margin: 5px;
				-webkit-transition: all 0.3s ease;
				transition: all 0.3s ease;
			}
			.line-1 {
				width: 27px;
			}
			.line-2 {
				width: 21px;
			}
			.line-3 {
				width: 26px;
			}
		}
		.language-switch-desktop{
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 7.5%;
			
		}
		.language-switch-mobile{
			display: none;
		}
		.language-switch{
			a{
				display: block;
				font-family: "Open Sans";
				padding: 2.5px 7px;
				cursor: pointer;
				opacity: .3;
				&:first-child{
					border-bottom: 1px solid rgba(0, 0, 0, .5);
				}
			}

			.active-language{
				opacity: 1;
			}
		}
		.burger-nav-active .line-1 {
			width: 20px;
		}
		.burger-nav-active .line-2 {
			width: 28px;
		}
		.burger-nav-active .line-3 {
			width: 23px;
		}
	}


}
@keyframes navLinkFade {
	from {
		opacity: 0;
		left: 50px;
	}
	to {
		opacity: 1;
		left: 0px;
	}
  }



  /* BLACK */ 
  .navbar-black{
	  background: #101010;
	  color: white !important;
	  z-index: 500;
  }
  .navbar-black .nav-content .nav-links a{
	  color: white;
  }
  .navbar-black .nav-content .nav-links .logo-desktop a p{
	border-top: 1px solid rgba(255, 255, 255, 0.4);
  }
  .navbar-black .nav-content .burger .line{
	background: white;
  }
  .navbar-black .nav-content .logo-mobile a{
	  color: white !important;
  }
  .navbar-black .nav-links{
		background: #101010 !important;
  }

  .navbar-media{
	  position: fixed;
	  z-index: 9999999;
	  .bottom-bar{
		background: #292929;
		position: fixed;

		  .wrapper{
			-webkit-box-sizing: initial;
			box-sizing: initial;
			overflow-x: scroll;
			overflow-y: hidden;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-overflow-scrolling: touch;
			scroll-behavior: smooth;
			height: 100%;
		  }
		  ul{
				display: flex;
				align-items: center;
				justify-content: center;
				-webkit-justify-content: center;
				height: 100%;
			width: 3000px;
			margin: 0 15vw;


				white-space: nowrap;

				
				li{
					list-style: none;
					padding: 15px 0px;
					position: relative;
					a{
					color: white;
					text-decoration: none;
					margin-right: 40px;
					position: relative;
					/* 
					&::before{
						content: "";
						position: absolute;
						width: 100%;
						transform: scaleX(1);
						height: 1px;
						bottom: -2px;
						left: 0;
						transform-origin: bottom right;
						transition: .25s;
						background: rgb(255, 255, 255);
					}
					&:hover::before{
						width: 0%;
					}
					*/
					}
				}
			}
	  }
  }

  #arrowR, #arrowL{
	  z-index: 84839489384093;
	  position: absolute;
	  top: 50%;
	  padding: 20px;
	  transform: translateY(-50%);
	  svg{
		  width: 12px;
		  height: auto;
	  }
  }
  #arrowL{
	  left: 1vw;

  }
  #arrowR{
	  right: 1vw;
  }
  #arrowR, #arrowL{
    display: none;
}

.navbar-black .nav-content .language-switch a:first-child{
	border-bottom: 1px solid rgba(255, 255, 255, .5);
}