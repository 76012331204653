.ensembles-section{
    width: 85vw;
    margin: 0 auto;
    h2{
        text-align: center;
    }
    .ensembles-grid{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 50px;
    }
    .sub-item{
        margin-top: 50px;
        overflow: hidden;

        &:hover{
            .overlay{
                opacity: .8;
            }
            img{
                -webkit-transform: scale(1.1);
                -moz-transform: scale(1.1);
                -ms-transform: scale(1.1);
                -o-transform: scale(1.1);
                transform: scale(1.1);
            }
        }
        position: relative;
        img{
            width: 100%;
            -webkit-transform: scale(1);
            -moz-transform: scale(1);
            -ms-transform: scale(1);
            -o-transform: scale(1);
            transform: scale(1);
            -webkit-transition: all 0.3s  ease;
            -moz-transition: all 0.3s  ease;
            -ms-transition: all 0.3s  ease;
            -o-transition: all 0.3s  ease;
            transition: all 0.3s ease;

        }
            .overlay {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                height: 100%;
                width: 100%;
                opacity: 0;
                transition: .5s ease;
                background-color: #0c0c0c;
                .text {
                    color: white;
                    font-size: 20px;
                    font-family: 'Libre Baskerville';
                    font-size: 50px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    -webkit-transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                    text-align: center;
                  }
              }
              
    }
    p{
        font-size: 24px;
        text-align: center;
        margin-top: 20px;
        font-family: 'Libre Baskerville';
    }
}

.ensemble-s{
    .item{
        width: 50%;
        margin: 0 auto;
    }
}