        /* YOUTUBE */
        .youtube-section{
            padding-bottom: 70px;
            width: 85vw;
            margin: 0 auto;
            margin-top: 20vh;
            .sound-F{
                width: 50vw;
                margin: 0 auto;
                display: none; 
                margin-top: 80px;
            }
            .sounds-s{
                width: 80vw;
                margin: 0 auto;
                margin-top: 60px;
                display: none;
                grid-template-columns: repeat(2, 1fr);
                gap: 50px;
                iframe{
                    display: block;
                    margin: 0 auto;
                }
            }
            h2{
                margin-bottom: 30px;
               
            }
            
        }

.media-bg{
    min-height: 100vh;
    color: white;
    .media-content{
        /* INTRO */
        h2{
        }
        ul{
            margin-top: 20px;
            display: flex;
            li{
                list-style: none;
                a{
                color: white;
                text-decoration: none;
                margin-right: 40px;
                position: relative;
                &::before{
                    content: "";
                    position: absolute;
                    width: 100%;
                    transform: scaleX(1);
                    height: 1px;
                    bottom: -2px;
                    left: 0;
                    transform-origin: bottom right;
                    transition: .25s;
                    background: rgb(255, 255, 255);
                }
                &:hover::before{
                    width: 0%;
                }
                }
            }
        }



      


            /* Diskographie & Publikationen */ 
    .media-grid-2{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 80px;
        margin-top: 20vh;
        h2{
            margin-bottom: 50px;

        }
        .item{
            h3{
                font-family: Libre Baskerville;
                font-weight: bold;
                font-size: 42px;
            }
        }
        .item:nth-child(1){
            .desc{
                font-size: 16px;
                margin-top: 20px;
                width: 80%;
            }
            .disko-grid{
                a{
                    text-decoration: none;
                    color: white;
                    display: block;
                .item-disko{
                    width: 100%;
                    border-bottom: 1px solid rgba(255, 255, 255, .4);
                    padding-bottom: 10px;
                    p{
                        font-size: 21px;
                    }
                    
                }
                &:not(:first-child){
                    margin-top: 15px;
                }
               
            }
            }
            .media-sub-grid{
                width: 90%;
                margin-top: 50px;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 20px;
                .item-sub{
                    img{
                        width: 100%;
                    }
                    p{
                        font-size: 16px;
                    }
                }
            }
        }
        .item:nth-child(2){
            .desc{
                margin-top: 20px;
    
                }
                .cover-grid{
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                }
                .cover-desc{
                    display: block;
                    text-decoration: none;
                    color: white;
                }
                .desc-a{  
                    color: black;
                    text-decoration: none;
                    position: relative;
                    
                    &::before{
                        content: '';
                        position: absolute;
                        width: 100%;
                        transform: scaleX(1);
                        height: 1px;
                        bottom: -2px;
                        left: 0;
                        transform-origin: bottom right;
                        transition: .25s;
                        background: black;
                    }
                    &:hover::before{
                        width: 0%;
                    }
                }
                img{
                    width: 90%;
                    display: inline-block;
                    filter: drop-shadow(1px 2px 16px rgba(0, 0, 0, 0.05));
                }
            }
    }

    /* SOCIAL MEDIA */ 
    .social-media-content{
        margin-top: 120px;
        padding-bottom: 150px;
        h2{
            margin-bottom: 20px;
        }
        .social-link-section{
            a{
                display: flex;
                align-items: center;
            svg, img{
                width: 80px;
                margin: 30px;
            }
            &:nth-child(1){
                img{
                    width: 120px;
                }
            }
        }
        }
    }

    }
    
}



.slick-slider .slick-arrow{
    display: none !important;
}
.slider-box{
    outline: none;
}

#soundcloud, #impressionen, #disko, #social{
    height: 90px;
}

  /* SOUNDCLOUD */ 
  .soundcloud-section{
    margin-top: 90px;
    .item{
        p{
            display: flex;
            justify-content: center;
            text-align: center;
            margin-top: 30px;
            position: relative;
            &::before{
                content: ' ';
                background: rgba(255, 255, 255, .3);
                height: 2px;
                width: 80%;
                position: absolute;
                bottom: -10px;
            z-index: -5;
            transition: transform .6s;
            transition: .9s transform;
            

            transform-origin: center;
            transform: scaleX(0);

            }
        }
    }
    .row-1{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 100px;
    }
    .row-2{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 100px;
        margin: 60px auto;
    }
    h3{
        font-size: 72px;
        margin-bottom: 10px;
    }
}

.fade-text-media[data-emergence=hidden]{
    &::before{
    transform: scaleX(0);
  }
}

.fade-text-media[data-emergence=visible]{
  &::before{

  transform: scaleX(1) !important;
  }
}