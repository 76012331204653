.hero-grid{
    margin-top: 120px;
    background: url(../assets/hero-f.jpg);
    height: 90vh;
    width: 100vw;
    background-size: cover;
    background-position-x: center;
    .hero-gradient{
        position: relative;
        height: 90vh;
        width: 100vw;
        background-size: cover;
        transition: background .4s;
    }
    .item{
        position: absolute;
        left: 50%;
        transform: translateX(-50%) !important;
        bottom: 22%;
        opacity: 0;
        display: none;
        h2{
            font-size: 125px;
            
}}
}

.hero-s{
    background: url(../assets/hero-s.jpg);
    height: 90vh;
    width: 100vw;
    background-size: cover;
    background-position-x: center;
}
.hero-f{
    background: url(../assets/hero-f.jpg);
    height: 90vh;
    width: 100vw;
    background-size: cover;
    background-position-x: center;
}