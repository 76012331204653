.gallery-section{
    width: 85vw;
    margin: 0 auto;
    h2{
        text-align: center;
    }
    .gallery-preview{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 50px;
        margin: 0 auto;

        margin-top: 40px;
        margin-bottom: 80px;
        width: 70%;
        .item{
            overflow: hidden;

            &:hover{
                .overlay{
                    opacity: .8;
                }
                img{
                    -webkit-transform: scale(1.1);
                    -moz-transform: scale(1.1);
                    -ms-transform: scale(1.1);
                    -o-transform: scale(1.1);
                    transform: scale(1.1);
                }
            }
            position: relative;
            img{
                width: 100%;
                -webkit-transform: scale(1);
                -moz-transform: scale(1);
                -ms-transform: scale(1);
                -o-transform: scale(1);
                transform: scale(1);
                -webkit-transition: all 0.3s  ease;
                -moz-transition: all 0.3s  ease;
                -ms-transition: all 0.3s  ease;
                -o-transition: all 0.3s  ease;
                transition: all 0.3s ease;

            }
                .overlay {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 100%;
                    width: 100%;
                    opacity: 0;
                    -webkit-transition: .5s ease;
                    -moz-transition: .5s ease;
                    -ms-transition: .5s ease;
                    -o-transition: .5s ease;
                    transition: .5s ease;
                    background-color: #0c0c0c;
                    .text {
                        color: white;
                        font-size: 20px;
                        font-family: 'Libre Baskerville';
                        font-size: 70px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        -webkit-transform: translate(-50%, -50%);
                        -ms-transform: translate(-50%, -50%);
                        transform: translate(-50%, -50%);
                        text-align: center;
                      }
                  }
                  
        }
    }
}




.gallery-e-section{
    width: 85vw;
    margin: 0 auto;
    margin-top: 25vh;
    margin-bottom: 25vh;
    .gallery-e-grid{
        margin-top: 40px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 50px;
        img{
            width: 100%;
            transition: .5s transform;
            &:hover{
                transform: scale(1.03);
            }
        }
        .normal-btn{
            padding: 10px 0px;
            width: 100%;
            text-align: center;
            display: inline-block;
            margin-top: 10px;
        }
        .col{
            .item{
                overflow: hidden;
                
            &:not(:first-child){
                margin-top: 50px;
            }
        }
        }
    }
}



.css-10yc0wa{
    z-index: 99999999999 !important;
}

.SRLThumbnailsButton, .SRLAutoplayButton, .SRLZoomOutButton{
    display: none !important;
}

.SRLExpandButton{
    opacity: 0 !important;
}

.SRLDownloadButton{
    width: auto !important;
    margin-right: 20px !important;
    display: none !important;
&::before{
    content: 'Download';
    border: 1px solid white;
    padding: 7px 25px;
    color: white;
    font-family: 'Open Sans';
    font-size: 23px;
    background: rgba(30,30,36,0.8);

}
}
.css-1x4ynwp div{
    display: none !important;
}

.css-oizohe{
    margin-top: 60px
}
.css-185typf{
    margin-top: 15px !important;
}
.css-138pcbv{
    margin-top: 10px;
}

.SRLImage{
    &::after{
        content: "tsst"
    }
}
#SRLLightbox{
    z-index: 99999999999 !important;
}