.references-section{
    width: 85vw;
    margin: 0 auto;
    margin-top: 15vh;
    margin-bottom: 20vh;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 80px;
    text-align: center;
    h2{
        font-size: 25px;
        line-height: 1.7;
        width: 60vw;
        margin: 0 auto;
        font-style: italic;
    }
    p{
        font-size: 18px;
        opacity: .7;
        margin-top: 10px;
    }
}