.animation-bi{
    height: 100%;
    width: 100vw;
    background: url(../assets/bio-f.jpg);
    background-position-x: center;
    margin-top: 15vh;
    .animation-bio{
    height: 100%;
    min-height: 1000px;
    width: 100vw;
    position: relative;
    }


}

.bio-f{
    height: auto;
    width: 100vw;
    background: linear-gradient(180deg, #232122 0%, rgba(35, 33, 34, 0) 23.25%),linear-gradient(360deg, #232122 0%, rgba(18, 18, 18, 0) 50%),  url(../assets/hero-f.jpg);
    background-position-x: center;
    background-size: 100%;
    background-repeat: no-repeat;
}
.bio-s{
    height: auto;
    width: 100vw;
    background: linear-gradient(180deg, #232122 0%, rgba(35, 33, 34, 0) 23.25%),linear-gradient(360deg, #232122 0%, rgba(18, 18, 18, 0) 50%),  url(../assets/hero-s.jpg);
    background-position-x: center;
    background-size: 100%;
    background-repeat: no-repeat;

}
.trigger{

.textbio{
    height: auto;
    width: 85vw;
    margin: 0 auto;
    color: white;
    opacity: 0;
    .inside-bio{
        padding-top: 150px; 
        
        .bio-text {
        column-count: 2;
        column-gap: 50px;
        line-height: 2.2;

            margin-top: 45px;
            p:not(:first-child){
    
                margin-top: 20px
            }

    }
    }


}
}
.animation-bio, .textbio{
}
.animation-bio{
    background: transparent;
    opacity: 0;
    position: relative;
}
.textbio{
    position: relative;
    top: -50px;
    opacity: 0;
}

  .loaded .animation-bio{
    animation: 1.6s fadeHero ease-in-out forwards .2s;
  }

  .loaded .textbio{
    animation: 1.2s fadeHText ease-in-out forwards .2s;
  }





  @keyframes fadeHero{
      from{
          background: transparent;
          opacity: 0;
      }
      to{
        background:  rgba(35, 33, 34, .8);
        opacity: 1;
      }
  }

  @keyframes fadeHText{
    from{
        opacity: 0;
        top: -50px;
    }
    to{
      opacity: 1;
      top: 0px;

    }
}