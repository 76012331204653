.switch-section{
    width: 100vw;
    height: 100vh;
    background: url(../assets/switch.jpg);
    background-size: cover;
    background-position-x: center;
    display: flex;
    align-items: center;
    justify-content: center;


    .switch-gradient{
        width: 100vw;
        height: 100%;
        background-size: cover;
        display: flex;
        background: none;
        opacity: 0;
        background: linear-gradient(86.66deg, rgba(0, 0, 0, 0.7) 0.42%, rgba(0, 0, 0, 0.1) 2.9%);
        position: absolute;
        top: 0;
        height: 100vh;
    }

        .switch-container-mobile{
            display: none;
        }
        .switch-container{
            opacity: 0;
            text-align: center;
            margin-top: -70px;
            .switch-grid{
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 80px;
                a{
                    color: white; 
                    text-decoration: none;
                    position: relative;
                    transition: .4s;
                    top: 0;
                    &:hover{
                        top: -10px;
                    }
                h2{
                    font-size: 62px;
                }
            }
                .line{
                    width: 65%;
                    height: 1px;
                    background: rgba(255, 255, 255, .4);
                    margin: 0 auto;
                    margin-top: 10px
                }
                margin-bottom: 40px;
            }
        }
}

.loaded{
    .switch-section .switch-container{
        animation: .9s fadeSwitchC ease-in-out forwards;
    }
    .switch-section .switch-gradient{
        opacity: 0;
        transform-origin: left;
        background: linear-gradient(89.81deg, rgba(0, 0, 0, 0.8) 37.05%, rgba(0, 0, 0, 0) 96.86%);

        animation: 1.5s fadeSwitchG forwards;

    }

}

@keyframes fadeSwitchC{
    from{
        opacity: 0;
        margin-top: -70px;
    }
    to{
        opacity: 1;
        margin-top: 0;
    }
}

@keyframes fadeSwitchG{
    from{
        opacity: 0;
        transform-origin: left;
        transform: translateX(-100%);
    }
    to{
        opacity: 1;
        transform: translateX(0%);

    }

}



.fade-about {

    span{   
        opacity: 0;
        position: relative; 
        &:nth-child(1){
            top: -20px;
        }
        &:nth-child(2){
            top: 20px;
        }
        &:nth-child(3){
            top: -20px;
        }
        &:nth-child(4){
            top: 20px;
        }
        &:nth-child(5){
            top: -20px;
        }
        &:nth-child(6){
            top: 20px;
        }
        &:nth-child(7){
            top: -20px;
        }
        &:nth-child(8){
            top: 20px;
        }
        &:nth-child(9){
            top: -20px;
        }
        &:nth-child(10){
          top: 20px;
      }
      &:nth-child(11){
        top: -20px;
    }
    &:nth-child(12){
      top: 20px;
  }
  &:nth-child(13){
    top: -20px;
  }
  &:nth-child(14){
    top: 20px;
  }
  &:nth-child(15){
    top: -20px;
  }
  &:nth-child(16){
    top: 20px;
  }
  &:nth-child(17){
    top: -20px;
  }
  &:nth-child(18){
    top: 20px;
  }
  &:nth-child(19){
    top: -20px;
  }
  &:nth-child(20){
    top: 20px;
  }
  &:nth-child(21){
    top: -20px;
  }
    }
  
  }
  
  .fade-about[data-emergence=hidden] {
    span{
    position: relative;
    opacity: 0;
  }
  span{   
    position: relative; 
    &:nth-child(1){
      top: -20px;
  }
  &:nth-child(2){
      top: 20px;
  }
  &:nth-child(3){
      top: -20px;
  }
  &:nth-child(4){
      top: 20px;
  }
  &:nth-child(5){
      top: -20px;
  }
  &:nth-child(6){
      top: 20px;
  }
  &:nth-child(7){
      top: -20px;
  }
  &:nth-child(8){
      top: 20px;
  }
  &:nth-child(9){
      top: -20px;
  }
  &:nth-child(10){
    top: 20px;
  }
  &:nth-child(11){
  top: -20px;
  }
  &:nth-child(12){
  top: 20px;
  }
  &:nth-child(13){
  top: -20px;
  }
  &:nth-child(14){
  top: 20px;
  }
  &:nth-child(15){
  top: -20px;
  }
  &:nth-child(16){
  top: 20px;
  }
  &:nth-child(17){
  top: -20px;
  }
  &:nth-child(18){
  top: 20px;
  }
  &:nth-child(19){
  top: -20px;
  }
  &:nth-child(20){
  top: 20px;
  }
  &:nth-child(21){
  top: -20px;
  }
  }
  
  }
  .fade-about[data-emergence=visible] {
  
    span{    
        &:nth-child(1){
            animation: .6s aboutFade1 ease-in-out forwards;
        }
        &:nth-child(2){
          animation: .6s aboutFade2 ease-in-out forwards .1s;
      }
      &:nth-child(3){
        animation: .6s aboutFade1 ease-in-out forwards .2s;
    }
    &:nth-child(4){
      animation: .6s aboutFade2 ease-in-out forwards .3s;
  }
  &:nth-child(5){
    animation: .6s aboutFade1 ease-in-out forwards .4s;
  }
  &:nth-child(6){
    animation: .6s aboutFade2 ease-in-out forwards .5s;
  }
  &:nth-child(7){
    animation: .6s aboutFade1 ease-in-out forwards .6s;
  }
  &:nth-child(8){
    animation: .6s aboutFade2 ease-in-out forwards .7s;
  }
  &:nth-child(9){
    animation: .6s aboutFade1 ease-in-out forwards .8s;
  }
  &:nth-child(10){
    animation: .6s aboutFade2 ease-in-out forwards .9s;
  }
  &:nth-child(11){
    animation: .6s aboutFade1 ease-in-out forwards 1s;
  }
  &:nth-child(12){
    animation: .6s aboutFade2 ease-in-out forwards 1.1s;
  }
  &:nth-child(13){
    animation: .6s aboutFade1 ease-in-out forwards 1.2s;
  }
  &:nth-child(14){
    animation: .6s aboutFade2 ease-in-out forwards 1.3s;
  }
  &:nth-child(15){
    animation: .6s aboutFade1 ease-in-out forwards 1.4s;
  }
  &:nth-child(16){
    animation: .6s aboutFade2 ease-in-out forwards 1.5s;
  }
  &:nth-child(17){
    animation: .6s aboutFade1 ease-in-out forwards 1.6s;
  }
  &:nth-child(18){
    animation: .6s aboutFade2 ease-in-out forwards 1.7s;
  }
  &:nth-child(19){
    animation: .6s aboutFade1 ease-in-out forwards 1.8s;
  }
  &:nth-child(20){
    animation: .6s aboutFade2 ease-in-out forwards 1.9s;
  }
  &:nth-child(21){
    animation: .6s aboutFade1 ease-in-out forwards 2s;
  }
    }
  }


  @keyframes aboutFade1{
    from{
        top: -20px;
        opacity: 0;
    }
    to{
        top: 0;
        opacity: 1;
    }
}
@keyframes aboutFade2{
    from{
        top: 20px;
        opacity: 0;
    }
    to{
        top: 0;
        opacity: 1;
    }
}

