* {
	padding: 0;
	margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Libre Baskerville', sans-serif;
	font-weight: 400;
}
/*Reset*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    vertical-align: baseline;
    background: transparent;
}
p,
a {
	font-family: "open sans";
}

.wrapper{
	width: 100vw;
	overflow-x: hidden;
}

body{
	background: #232122;
	color: white;
}

.align-horizontal{
	display: flex;
	justify-content: center;
}

.align-vertical{
	display: flex;
	align-items: center;
}
.fade-in{
	transition: opacity .9s, transform .9s;
}
.fade-in[data-emergence=hidden] {
	opacity: 0;
	transform: translateY(40px);
  }
  .fade-in[data-emergence=visible] {
	opacity: 1;
	transform: translateY(0px);

  }

  .media-content{
	.fade[data-emergence=hidden]{
		opacity: 0;  
	}
	  .fade[data-emergence=visible]{
		animation: fadein .9s forwards;
		&:nth-child(2){
			animation-delay: .3s;
		}
	  }
  }

  @keyframes fadein{
	  from{
		opacity: 0;
		transform: translateY(40px);
	  }
	  to{
		opacity: 1;
		transform: translateY(0px);
		visibility: visible !important;

	  }
  }

 .normal-headline{
	 font-size: 92px;
 }

 .normal-btn{
	 color: white;
	 text-decoration: none;
	 padding: 10px 65px;
	 border: 1.5px solid white;
	 display: inline-block;
	 transition: .4s;
	 &:hover{
		 background: white;
		 color: black;
	 }
 }



 #paedagogik, #calendar, #impressions, #gallery{
	 height: 120px;
 }
 


 .fade-in-contactgradient[data-emergence=hidden] {
	background: transparent;
  }
  .fade-in-contactgradient[data-emergence=visible] {
	background: rgba(35, 33, 34, .9);

  }

  .fade-in-contacttext{
	  opacity: 0;
	  transition: top .9s, opacity .7s;
  }
  .fade-in-contacttext[data-emergence=hidden] {
	opacity: 0;
	position: relative;
	top: -50px;

  }


  .fade-in-contacttext[data-emergence=visible] {
	opacity: 1;
	position: relative;
	top: 0px;
	
  }